import { render, staticRenderFns } from "./logbook-dashboard.vue?vue&type=template&id=3d446e1e&scoped=true&data-testid=logbook-dashboard-component&"
import script from "./logbook-dashboard.vue?vue&type=script&setup=true&lang=js&"
export * from "./logbook-dashboard.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./logbook-dashboard.vue?vue&type=style&index=0&id=3d446e1e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d446e1e",
  null
  
)

export default component.exports
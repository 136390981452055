<template data-testid="logbook-dashboard-component">
  <v-container>
    <v-row align="center" class="pt-3">
      <v-col>
        <span data-testid="logbook-dashboard-title" class="card-title">
          {{ t('Title_.page') }}
        </span>
      </v-col>
    </v-row>
    <v-row class="pt-4 ma-0 d-flex">
      <v-col class="d-flex pa-0 flex-row">
        <IconButton
          data-testid="logbook-dashboard-day-before-btn"
          class="icon-btn mt-1 background-white height-40 width-40"
          icon="mdi-arrow-left"
          :border="false"
          @click="checkForChangesBeforeChangeDay(-1)"
          :disabled="loadingStore.isPartialLoading"
        />
        <DatePicker
          data-testid="logbook-dashboard-date-picker"
          class="px-4"
          :value="logbookStore.selectedDate"
          @change="checkForChangesBeforeChangeDate($event)"
          :disabled="loadingStore.isPartialLoading"
        />
        <IconButton
          data-testid="logbook-dashboard-day-after-btn"
          class="icon-btn mt-1 background-white height-40 width-40"
          icon="mdi-arrow-right"
          :border="false"
          @click="checkForChangesBeforeChangeDay(+1)"
          :disabled="loadingStore.isPartialLoading"
        />
      </v-col>
      <v-col class="d-flex flex-row-reverse pa-0">
        <DropdownInput
          data-testid="logbook-dashboard-select-hub-dropdown"
          v-model="logbookStore.selectedHub"
          @change="checkForChangesBeforeChangeHub($event)"
          :items="logbookStore.hubList"
          :disabled="
            logbookStore.hubList.length <= 1 || loadingStore.isPartialLoading
          "
          :clearable="false"
          hide-details
          item-text="name"
          item-value="id"
        />
      </v-col>
    </v-row>
    <v-card class="rounded-lg shadow-regular">
      <v-container>
        <v-row>
          <v-col>
            <v-card-title
              data-testid="logbook-dashboard-journeys-stored-subtitle"
              class="section-title"
              >{{ t('Title_.subTitle.journeysStored') }}
            </v-card-title>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              data-testid="logbook-dashboard-save-journeys-btn"
              class="main-action-btn mr-4 mt-4"
              :disabled="userCannotAssignDrivers"
              @click="logbookStore.assignDriversToJourneys()"
            >
              {{ t('Action_.buttons.saveChanges') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row>
            <v-col class="pa-0">
              <LogbookTable
                data-testid="logbook-table"
                @update-journey-groups="
                  checkForChangesBeforeUpdateJourneyGroups($event)
                "
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </v-container>
</template>

<script setup>
import { onBeforeRouteLeave } from 'vue-router/composables';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useLogbookStore } from '@/store/pinia/logbook.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import helpers from '@/helpers/helpers';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import languages from '../locales/languages';
import LogbookTable from './logbook-table.vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });
const loadingStore = useLoadingStore();
const logbookStore = useLogbookStore();
const nextRoute = ref(null);
const previousHub = ref('');
const previousVehicleSelected = ref('');
const previousAssignmentStatus = ref('');
const popupDialogStore = usePopupDialogStore();
const { trackPage } = amplitudeComposable();

watch(
  () => logbookStore.selectedHub,
  (newValue, oldValue) => {
    previousHub.value = oldValue;
  }
);

watch(
  () => logbookStore.vehicleSelected,
  (newValue, oldValue) => {
    previousVehicleSelected.value = oldValue;
  }
);

watch(
  () => logbookStore.assignmentStatus,
  (newValue, oldValue) => {
    previousAssignmentStatus.value = oldValue;
  }
);

// --- Methods ---
const checkForChangesBeforeChangeDay = (day) => {
  if (isFormAltered()) {
    displayConfirmationDialog(() => {
      changeDay(day);
    });
  } else {
    changeDay(day);
  }
};

const revertHub = () => {
  logbookStore.selectedHub = previousHub.value;
};

const revertPlate = () => {
  logbookStore.vehicleSelected = previousVehicleSelected.value;
};

const revertAssignment = () => {
  logbookStore.assignmentStatus = previousAssignmentStatus.value;
};

const changeDay = async (dayToChange) => {
  let date = new Date(logbookStore.selectedDate);
  const dayToSet = date.getDate() + dayToChange;
  date.setDate(dayToSet);
  date = date.toISOString().substring(0, 10);
  await changeDate(date);
};

const checkForChangesBeforeChangeDate = (date) => {
  if (isFormAltered()) {
    displayConfirmationDialog(() => {
      changeDate(date);
    });
  } else {
    changeDate(date);
  }
};

const changeDate = async (date) => {
  logbookStore.selectedDate = date;
  await logbookStore.getJourneyGroups();
  trackPage(logbookStore.selectedHub, {
    resourceId: logbookStore.selectedDate,
  });
};

const checkForChangesBeforeChangeHub = (hub) => {
  if (isFormAltered()) {
    displayConfirmationDialog(
      () => {
        changeHub(hub);
      },
      () => {
        revertHub();
      }
    );
  } else {
    changeHub(hub);
  }
};

const changeHub = async (hub) => {
  logbookStore.selectedHub = hub;
  await logbookStore.getHubEmployees();
  await logbookStore.getVehiclesPlatesByHub();
  await logbookStore.getJourneyGroups();
  trackPage(logbookStore.selectedHub, {
    resourceId: logbookStore.selectedDate,
  });
};

const checkForChangesBeforeUpdateJourneyGroups = async (event) => {
  let cancelAction = () => null;

  if (event === 'plate') {
    cancelAction = () => revertPlate();
  } else if (event === 'assignment') {
    cancelAction = () => revertAssignment();
  }

  if (isFormAltered()) {
    displayConfirmationDialog(async () => {
      await logbookStore.getJourneyGroups();
    }, cancelAction);
  } else {
    trackPage(logbookStore.selectedHub, {
      resourceId: logbookStore.vehicleSelected || logbookStore.selectedDate,
      routeName: 'logbook.journeysDetails',
    });
    await logbookStore.getJourneyGroups();
  }
};

const displayConfirmationDialog = (action, cancel) => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.wantToCancel.title'),
    message: t('Action_.dialog.wantToCancel.message'),
    actionBtnText: t('Action_.buttons.proceed'),
    actionBtn: action,
    cancelBtn: cancel,
  };
  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const leavePage = (next) => {
  if (nextRoute.value) next();
};

const isFormAltered = () => {
  if (logbookStore.unmodifiedJourneyGroups) {
    return !helpers.compareTwoObjects(
      logbookStore.journeyGroups,
      logbookStore.unmodifiedJourneyGroups
    );
  }
  return false;
};
const clearNextRouteData = () => {
  nextRoute.value = null;
};

// --- Computed ---
const userCannotAssignDrivers = computed(() => {
  return (
    !isFormAltered() ||
    !canUser(['Fleet.Logbook.AssignDriver']) ||
    loadingStore.isPartialLoading
  );
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  clearNextRouteData();
  if (!isFormAltered() || to.name === 'Error') {
    nextRoute.value = to;
  }
  if (nextRoute.value) {
    next();
  } else {
    nextRoute.value = to;
    displayConfirmationDialog(() => leavePage(next));
  }
});
</script>

<style lang="scss" scoped>
.background-white {
  background-color: #fff;
}
.height-40 {
  height: 40px !important;
}
.width-40 {
  width: 40px !important;
}
</style>

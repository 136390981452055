import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useProfileStore } from './profile.store';
import { useUserStore } from '@/store/pinia/user.store.js';

export const useLogbookStore = defineStore('logbook', () => {
  const loadingStore = useLoadingStore();
  const mainStore = useMainStore();
  const profileStore = useProfileStore();
  const userStore = useUserStore();

  // --- Local Variables ---
  const assignmentStatus = ref(null);
  const assignmentStatusTypes = ref([
    { Name: 'All', Value: null },
    { Name: 'Assigned', Value: true },
    { Name: 'Unassigned', Value: false },
  ]);
  const expandedTableRows = ref([]);
  const hubEmployees = ref([]);
  const hubList = ref([]);
  const journeyGroups = ref([]);
  const pagination = ref({
    pageSizes: [5, 10, 20],
    page: 1,
    length: 1,
    pageSize: 5,
    total: 0,
    category: 0,
  });
  const selectedDate = ref(null);
  const selectedHub = ref(null);
  const unmodifiedJourneyGroups = ref(null);
  const vehiclePlatesInformation = ref([]);
  const vehicleSelected = ref(null);

  // --- Methods ---
  const getHubList = async () => {
    const params = {
      endpoint: `v2/api/fleet/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('logbookHubListLoading');
    try {
      hubList.value = await mainStore.request(params);
      selectedHub.value = hubList.value[0].id;
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('logbookHubListLoading');
    }
  };

  const getFirstSelectedHub = async () => {
    loadingStore.startGlobalLoading('getFirstSelectedHub');
    try {
      if (hubList.value.length > 1) {
        const profileMenuInformation =
          await profileStore.getProfileMenuInformation(userStore.userInfo.sub);

        const office =
          profileMenuInformation.offices?.mainOffice?.id ||
          profileMenuInformation.offices?.secondaryOffice?.id;

        const officeInHubList = hubList.value.find((hub) => hub.id === office);
        if (officeInHubList) {
          selectedHub.value = office;
        } else {
          selectedHub.value = hubList.value[0].id;
        }
      } else if (hubList.value[0]) {
        selectedHub.value(hubList.value[0].id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getFirstSelectedHub');
    }
  };

  const getHubEmployees = async () => {
    const params = {
      endpoint: `v2/api/Employees?page=1&pageSize=500&officeId=${selectedHub.value}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubEmployees');
    try {
      const response = await mainStore.request(params);
      hubEmployees.value = response.items;
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('getHubEmployees');
    }
  };

  const getVehiclesPlatesByHub = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('startDate', selectedDate.value);
    urlencoded.append('endDate', selectedDate.value);

    const params = {
      endpoint: `v2/api/hubs/${selectedHub.value}/fleet-logbook-vehicles`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getVehiclesPlatesByHub');
    try {
      vehiclePlatesInformation.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getVehiclesPlatesByHub');
    }
  };

  const getJourneyGroups = async () => {
    loadingStore.startPartialLoading('getJourneyGroups');

    const urlencoded = new URLSearchParams();

    const nextDay = new Date(selectedDate.value);
    const dayToSet = nextDay.getDate() + 1;
    nextDay.setDate(dayToSet);
    const endDate = nextDay.toISOString().substring(0, 10);

    urlencoded.append('startDate', selectedDate.value);
    urlencoded.append('endDate', endDate);

    if (vehicleSelected.value) {
      urlencoded.append('vehicleId', vehicleSelected.value);
    }

    if (assignmentStatus.value === true || assignmentStatus.value === false) {
      urlencoded.append('isAssigned', assignmentStatus.value);
    }

    const params = {
      endpoint: `v2/api/hubs/${selectedHub.value}/fleet-logbook`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };
    try {
      expandedTableRows.value = [];
      let journeysResponse = await mainStore.request(params);

      journeyGroups.value = journeysResponse.map((journey, index) => {
        return { id: index, ...journey };
      });

      unmodifiedJourneyGroups.value = JSON.parse(
        JSON.stringify(journeyGroups.value)
      );
      pagination.value.total = journeyGroups.value.length;
      pagination.value.length = Math.ceil(
        pagination.value.total / pagination.value.pageSize
      );
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('getJourneyGroups');
    }
  };

  const assignDriversToJourneys = async () => {
    const allSubjourneys = journeyGroups.value.reduce(
      (previousJourney, nextJourney) => {
        return previousJourney.concat(nextJourney.subJourneys);
      },
      []
    );

    const allSubjourneysUnmodified = unmodifiedJourneyGroups.value.reduce(
      (previousJourney, nextJourney) => {
        return previousJourney.concat(nextJourney.subJourneys);
      },
      []
    );

    const journeysWithDriverChanged = allSubjourneys
      .filter((subJourney) => {
        const unmodifiedJourney = allSubjourneysUnmodified.find(
          (journey) => journey.journeyId === subJourney.journeyId
        );
        return unmodifiedJourney.driver !== subJourney.driver;
      })
      .map((modifiedJourney) => {
        return {
          journeyId: modifiedJourney.journeyId,
          employeeId: modifiedJourney.driver,
        };
      });

    const params = {
      endpoint: `v2/api/hubs/${selectedHub.value}/fleet-logbook/assign-drivers`,
      configs: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: journeysWithDriverChanged,
      },
    };

    loadingStore.startPartialLoading('assignDriversToJourneys');
    try {
      await mainStore.request(params);
      await getJourneyGroups();
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('assignDriversToJourneys');
    }
  };

  // --- Computed ---
  const journeyGroupsToDisplay = computed(() => {
    const startIndex = pagination.value.pageSize * (pagination.value.page - 1);
    const endIndex = startIndex + pagination.value.pageSize;
    return journeyGroups.value.slice(startIndex, endIndex);
  });

  return {
    getFirstSelectedHub,
    getHubEmployees,
    getHubList,
    getJourneyGroups,
    getVehiclesPlatesByHub,
    assignDriversToJourneys,
    assignmentStatus,
    assignmentStatusTypes,
    expandedTableRows,
    hubEmployees,
    hubList,
    journeyGroups,
    journeyGroupsToDisplay,
    pagination,
    selectedDate,
    selectedHub,
    unmodifiedJourneyGroups,
    vehiclePlatesInformation,
    vehicleSelected,
  };
});

<template data-testid="logbook-table-component">
  <v-container>
    <v-row>
      <v-col cols="3">
        <DropdownInput
          :items="logbookStore.vehiclePlatesInformation"
          :label="t('Msg_.carPlate')"
          :placeholder="t('Msg_.all')"
          @change="updateJourneyGroups('plate', $event)"
          data-testid="logbook-table-carplate-dropdown"
          hide-details
          item-text="plate"
          item-value="id"
          v-model="logbookStore.vehicleSelected"
        />
      </v-col>
      <v-col cols="3">
        <DropdownInput
          :items="selectAssignmentStatusType"
          :label="t('Msg_.assignmentStatus')"
          :placeholder="t('Msg_.all')"
          @change="updateJourneyGroups('assignment', $event)"
          data-testid="logbook-table-assignment-dropdown"
          hide-details
          v-model="logbookStore.assignmentStatus"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :expanded.sync="logbookStore.expandedTableRows"
          :headers="headersMainTable"
          :items-per-page="logbookStore.pagination.pageSize"
          :items="logbookStore.journeyGroupsToDisplay"
          :single-expand="false"
          :loading="loadingStore.isPartialLoading"
          :no-data-text="t('Msg_.noDataAvailable')"
          sort-by="startDate"
          hide-default-footer
          item-key="id"
          show-expand
        >
          <template #[`item.startDate`]="{ item }">
            <span>
              {{ helpers.getTimeFromDate(item.startDate) }}
            </span>
          </template>
          <template #[`item.endDate`]="{ item }">
            <span>
              {{ helpers.getTimeFromDate(item.endDate) }}
            </span>
          </template>

          <template #[`item.driver`]="{ item }">
            <DropdownInput
              :items="logbookStore.hubEmployees"
              :placeholder="getDriverPlaceholderForJourneyGroup(item)"
              @change="setSingleDriverForAllJourneys(item)"
              class="d-flex align-center"
              hide-details
              item-text="completeName"
              item-value="id"
              v-model="item.driver"
              :disabled="shouldDisableMultipleSelection(item)"
            />
          </template>
          <template #expanded-item="{ headers, item }">
            <td class="pa-0" :colspan="headers.length">
              <v-data-table
                :headers="headersSecondaryTable"
                :items="item.subJourneys"
                :items-per-page="-1"
                hide-default-footer
              >
                <!--eslint-disable-next-line vue/no-template-shadow-->
                <template #[`item.driver`]="{ item }">
                  <DropdownInput
                    :disabled="!item.isDriverSelectionAllowed"
                    :items="logbookStore.hubEmployees"
                    :placeholder="getDriverPlaceholderForSubjourney(item)"
                    @change="changeSingleDriverSelected(item)"
                    class="d-flex align-center"
                    hide-details
                    item-text="completeName"
                    item-value="id"
                    v-model="item.driver"
                  />
                </template>
                <!--eslint-disable-next-line vue/no-template-shadow-->
                <template #[`item.duration`]="{ item }">
                  <p class="mb-0">
                    {{ `${item.duration} ${t('Msg_.minutes')}` }}
                  </p>
                </template>
                <!--eslint-disable-next-line vue/no-template-shadow-->
                <template #[`item.startDate`]="{ item }">
                  <span>
                    {{ helpers.getTimeFromDate(item.startDate) }}
                  </span>
                </template>
                <!--eslint-disable-next-line vue/no-template-shadow-->
                <template #[`item.endDate`]="{ item }">
                  <span>
                    {{ helpers.getTimeFromDate(item.endDate) }}
                  </span>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <Pagination
          data-testid="logbook-table-pagination"
          type="wide"
          v-model="logbookStore.pagination"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useLogbookStore } from '@/store/pinia/logbook.store.js';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';

const { t, tm } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits('update-journey-groups');
const loadingStore = useLoadingStore();
const logbookStore = useLogbookStore();

// --- Methods ---
const updateJourneyGroups = (type, event) => {
  if (event !== '') {
    emit('update-journey-groups', type);
  }
};

const setSingleDriverForAllJourneys = (journeyGroup) => {
  journeyGroup.subJourneys.forEach((subJourney) => {
    if (subJourney.isDriverSelectionAllowed) {
      subJourney.driver = journeyGroup.driver;
    }
  });
};

const changeSingleDriverSelected = (_subJourney) => {
  const journeyGroupSelected = logbookStore.journeyGroups.find(
    (journeyGroup) =>
      !!journeyGroup.subJourneys.find(
        (item) => item.journeyId === _subJourney.journeyId
      )
  );

  const allDrivers = journeyGroupSelected.subJourneys
    .filter((subJourney) => subJourney.isDriverSelectionAllowed === true)
    .map((item) => item.driver);

  const allDriversAreTheSame = allDrivers.every(
    (driver) => driver === allDrivers[0]
  );

  if (allDriversAreTheSame) {
    journeyGroupSelected.driver = allDrivers[0];
  } else {
    journeyGroupSelected.driver = null;
  }
};

const getDriverPlaceholderForJourneyGroup = (journeyGroup) => {
  if (journeyGroup.driver && !getEmployeeHubFromId(journeyGroup.driver)) {
    return t('Msg_.assignedToOtherHub');
  }
  const subJourneysWithDriverSelected = journeyGroup.subJourneys.filter(
    (subjourney) => subjourney.driver !== null
  );
  if (subJourneysWithDriverSelected.length === 0) {
    return t('Msg_.chooseDriver');
  }
  return t('Msg_.multipleSelection');
};

const getDriverPlaceholderForSubjourney = (subjourney) => {
  if (!subjourney.isDriverSelectionAllowed) {
    return '';
  } else if (!subjourney.driver) {
    return t('Msg_.chooseDriver');
  } else if (!getEmployeeHubFromId(subjourney.driver)) {
    return t('Msg_.assignedToOtherHub');
  }
};

const getEmployeeHubFromId = (driverId) => {
  const employee = logbookStore.hubEmployees.find(
    (employee) => employee.id === driverId
  );
  if (employee) {
    return employee;
  }
  return null;
};

const shouldDisableMultipleSelection = (journeyGroup) => {
  return journeyGroup.subJourneys.every(
    (journey) => !journey.isDriverSelectionAllowed
  );
};

// --- Computed ---
const headersMainTable = computed(() => {
  return [
    { text: '', value: 'data-table-expand', class: '', width: 40 },
    {
      text: tm('Msg_.tableHeaders')['carPlate'],
      value: 'carPlate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
      width: 140,
    },

    {
      text: tm('Msg_.tableHeaders')['start'],
      value: 'startDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
      width: 140,
    },
    {
      text: tm('Msg_.tableHeaders')['end'],
      value: 'endDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
      width: 140,
    },

    {
      text: tm('Msg_.tableHeaders')['driver'],
      value: 'driver',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
      width: 340,
    },

    {
      text: '',
      value: '',
      sortable: false,
    },
  ];
});

const headersSecondaryTable = computed(() => {
  return [
    {
      text: tm('Msg_.tableHeaders')['carPlate'],
      value: 'carPlate',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
    },

    {
      text: tm('Msg_.tableHeaders')['start'],
      value: 'startDate',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.tableHeaders')['end'],
      value: 'endDate',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.tableHeaders')['destination'],
      value: 'endLocation.address',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.tableHeaders')['duration'],
      value: 'duration',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
    },
    {
      text: tm('Msg_.tableHeaders')['driver'],
      value: 'driver',
      class: 'uppercase-header bg-ash-regular',
      align: 'center',
      sortable: false,
      width: 340,
    },
  ];
});

const selectAssignmentStatusType = computed(() => {
  let actionSelectAssignmentType = tm('Action_.select.selectAssignment');
  return logbookStore.assignmentStatusTypes.map((type) => {
    return {
      text: actionSelectAssignmentType[type.Name.toLowerCase()],
      value: type.Value,
    };
  });
});
</script>
<style lang="scss" scoped>
::v-deep .v-data-table-header .bg-ash-regular {
  background-color: $ash-light;
}

::v-deep .v-data-table__expand-icon.v-icon {
  color: $mine-black !important;
  background-color: $ash-dark;
  border-radius: 12px !important;
}

td {
  background-color: white !important;
}
</style>
